import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { uploadImageAction } from "../../redux/actions";
const UploadReceipt = (props) => {
	const { t } = useTranslation();
	let [fileurl, setFileurl] = useState(null);
	let [selectedFile, setSelectedFile] = useState(null);
	const inputFile = React.useRef();
	const onselectFile = (event) => {
		setFileurl(null);
		setSelectedFile(null);
		const imageFile = event.target.files[0];
		if (!imageFile) {
			toast.error(`${t("Error")}! ${t("Select a receipt")}`);
			return false;
		}
		if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
			inputFile.current.value = "";
			toast.error(`${t("Error")}! ${t("Select valid image")}`);
			return false;
		}
		if (Math.round(imageFile.size / 1024) > 10240) {
			inputFile.current.value = "";
			toast.error(`${t("Error")}! ${t("Selected file is too Big")}`);
			return false;
		}
		let reader = new FileReader();
		reader.onloadend = function() {
			setFileurl(reader.result);
		};
		reader.readAsDataURL(imageFile);
		setSelectedFile(imageFile);
	};
	const resetUpload = () => {
		inputFile.current.value = "";
		setFileurl(null);
		setSelectedFile(null);
	};
	const uploadImage = () => {
		if (!fileurl) {
			toast.error(`${t("Error")}! ${t("Select a receipt")}`);
			return false;
		}
		const formData = new FormData();
		formData.append("type", "receipt");
		formData.append("image", selectedFile);

		props.setLoading(true);
		uploadImageAction(formData)
			.then((responseData) => {
				props.setLoading(false);
				if (responseData["httpCode"] === 200) {
					toast.success(`${t("Your receipt has been uploaded successfully. We will get back to you within 24-48 hours once we verify it")}.`);
					setFileurl(null);
					inputFile.current.value = "";
					return true;
				} else if (responseData["httpCode"] === 409) {
					toast.error(`${t("Error")}! ${t("Please try again")}. ${t("Provided receipt has already been submitted for this campaign before")}`);
					inputFile.current.value = "";
					setFileurl(null);
					return true;
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);
					return false;
				}
			})
			.catch((err) => {
				console.log("error", err);
				props.setLoading(false);
				if (typeof err === "object") {
					if (err["response"]) {
						if (err["response"].httpCode === 409) {
							//duplicate Receipt message
							toast.error(`${t("Error")}! ${t("Please try again")}. ${t("Provided receipt has already been submitted for this campaign before")}`);
							inputFile.current.value = "";
							setFileurl(null);
							return false;
						} else {
							toast.error(`${t("Error")}! ${t("Something went wrong")}`);
							return false;
						}
					} else {
						toast.error(`${t("Error")}! ${t("Something went wrong")}`);
						return false;
					}
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);
					return false;
				}
			});
	};
	return (
		<Container fluid>
			<div className="validate-purchase-div">
				<Container fluid>
					<Row xs={1} md={1} lg={1}>
						<h2 className="h2-title">
							<Trans>IS YOUR MOVIE ELIGIBLE FOR POINTS</Trans>?
						</h2>
					</Row>
					<Row xs={1} md={1} lg={1}>
						<h2 className="h2-disc1">
							<Trans>Check Here</Trans>!
						</h2>
					</Row>
					{fileurl ? (
						<Row xs={1} md={1} lg={1}>
							<div className="receipt-preview">
								<img alt="receipt file" src={fileurl} />
							</div>
						</Row>
					) : (
						""
					)}
					<Row xs={1} md={1} lg={1}>
						<label className="label-disc">
							<Trans>Image Type Supported</Trans>: jpg/jpeg/png
						</label>
						<label className="label-disc">
							<Trans>Max File Size</Trans>: 10MB
						</label>
					</Row>
					<Row>
						<div className="uploade-div-container">
							<label className="custom-file">
								<Trans>UPLOAD RECEIPT</Trans>
								<input type="file" ref={inputFile} className="custom-file-input" id="customInput" onChange={onselectFile} aria-describedby="fileHelp" accept="image/*" />
								<span className="custom-file-control form-control-file"></span>
							</label>
						</div>
					</Row>
					<Row>
						<div className="btn-div">
							<button type="button" onClick={uploadImage} className="btn btn-primary" id="upload-btn">
								<Trans>Upload Now</Trans>
							</button>
							<p align="center">
								<Link to="#" onClick={resetUpload} id="href-reset">
									<Trans>Reset</Trans>
								</Link>
							</p>
						</div>
					</Row>
				</Container>
			</div>
		</Container>
	);
};

export default IsLoadingHOC(UploadReceipt, "Loading....");
