import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Col, Row, Button, Card, FormControl, InputGroup } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import IsLoadingHOC from "../IsLoadingHOC";
import { referFriendsAction } from "../../redux/actions";
const ReferFriend = (props) => {
	const { t } = useTranslation();
	const referValueLimit = 5;
	const activityData = props.activityData;
	let [valuelist, setValuelist] = useState([{ value: "", isValid: true }]);
	let [valueStatusList, setValueStatusList] = useState([]);
	useEffect(() => {}, []);
	const valueListUpdate = (ind) => {
		let valueTextFiled = [...valuelist];
		if (ind === valueTextFiled.length - 1) {
			valueTextFiled.push({ value: "", isValid: true });
		} else {
			valueTextFiled.splice(ind, 1);
		}
		setValuelist(valueTextFiled);
	};
	const updatevalueList = (e, ind) => {
		let valueTextFiled = [...valuelist];
		valueTextFiled[ind].value = e.target.value;
		if (e.target.value === "" || isValidEmail(e.target.value)) {
			valueTextFiled[ind].isValid = true;
		} else {
			valueTextFiled[ind].isValid = false;
		}
		setValuelist(valueTextFiled);
	};
	const resetComponent = () => {
		setValuelist([{ value: "", isValid: true }]);
		setValueStatusList([]);
	};
	const referFriendBtnClick = () => {
		let valueTextFiled = [...valuelist];
		let tempValueList = valueTextFiled.filter((v) => !v.isValid);
		if (tempValueList.length > 0) {
			return false;
		}
		tempValueList = valueTextFiled.filter((v) => v.value !== "");
		if (tempValueList.length === 0) {
			setValuelist([{ value: "", isValid: true }]);
		}
		if (valueTextFiled[0].value === "") {
			toast.error(`${t("Error")}! ${t("Please try again")}. ${t("Please enter at least one email and not more than")} ${referValueLimit} ${t("emails")}`);
			return false;
		}
		props.setLoading(true);
		let values = tempValueList.map((v) => ({ type: "email", id: v.value }));
		referFriendsAction({ idList: values })
			.then((responseData) => {
				if (responseData["data"]) {
					setValueStatusList(responseData["data"].statusList);
					props.setLoading(false);
				} else {
					setValueStatusList([]);
					props.setLoading(false);
				}
			})
			.catch((err) => {
				setValueStatusList([]);
				props.setLoading(false);
				console.log("error in api call", err);
			});
	};
	let isValidEmail = (email) => {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(email)) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<Container className="portal-point-list-container">
			<Container fluid>
				<div className="label">
					<Link id="headerParentLink" className="parent-link" to="/earnpoints">
						<Trans>Earn Points</Trans>
					</Link>{" "}
					<b>{activityData["title"] ? activityData.title : ""}</b>
				</div>{" "}
			</Container>
			<Container fluid>
				<Row xs={1} md={2} lg={2}>
					<Col>
						<Card>
							<Card.Img variant="top" src={activityData.cardHeaderImage} />
							<Card.Body>
								<Card.Text>
									<Trans>Perform this action to get</Trans> {activityData["points"] ? activityData.points : 0} <Trans>Points</Trans>
								</Card.Text>
							</Card.Body>
						</Card>
						{valueStatusList.length === 0 ? (
							<>
								{valuelist.map((v, i) => {
									return (
										<Row key={`row${i}`}>
											<Col xs={10} md={10}>
												<InputGroup className="mb-3">
													<InputGroup.Text id="basic-addon3">
														{" "}
														<Trans>Email</Trans>{" "}
													</InputGroup.Text>
													<FormControl
														id="basic-url"
														name={`value${i}`}
														aria-describedby="basic-addon3"
														placeholder=""
														onChange={(e) => {
															updatevalueList(e, i);
														}}
														className={v.isValid ? "" : "is-invalid"}
														value={v.value}
														maxLength={100}
														id={`enter-value-textbox-${i}`}
														aria-describedby={`enter-value-textbox-feedback-${i}`}
														required
													/>

													{v.isValid ? (
														""
													) : (
														<div id={`enter-value-textbox-feedback-${i}`} class="invalid-feedback">
															<Trans>Please enter a valid</Trans> <Trans> Email</Trans>.
														</div>
													)}
												</InputGroup>
											</Col>
											<Col xs={2} md={2}>
												{valuelist.length === referValueLimit && i === valuelist.length - 1 ? (
													""
												) : (
													<Button
														type="button"
														onClick={(e) => {
															valueListUpdate(i);
														}}
														className="btn btn-primary"
														id={i === valuelist.length - 1 ? `add-value-btn` : `del-value-btn-${i}`}>
														{i === valuelist.length - 1 ? "+" : "-"}
													</Button>
												)}
											</Col>
										</Row>
									);
								})}
								<Row>
									<Col md={{ span: 4, offset: 4 }} sm={{ span: 4, offset: 4 }}>
										<Button type="button" onClick={referFriendBtnClick} className="btn btn-primary" id="submit-value-btn">
											<Trans>Refer Friends</Trans>
										</Button>
									</Col>
								</Row>
							</>
						) : (
							<>
								{valueStatusList.map((v, i) => {
									return (
										<Row className="justify-content-md-center">
											<Col xs lg="12" className={v.isValid ? "response-message-div text-center bg-success" : "response-message-div text-center bg-warning"}>
												<h5 className="text-center">{v.id}</h5>
												{v.isValid ? (
													<p className="validate-code-response-msg" id={`msg-${v.id}-${i}`}>
														<Trans>You've successfully referred your friend</Trans>.
													</p>
												) : (
													<p className="validate-code-response-msg" id={`msg-${v.id}-${i}`}>
														<Trans>Sorry</Trans>! <Trans>This email is not valid</Trans>. <Trans>Please make sure you have entered valid email</Trans>.
													</p>
												)}
											</Col>
										</Row>
									);
								})}
								<Row className="justify-content-md-center">
									<Col xs lg="6" className="text-center">
										<div className="btn-div">
											<button type="button" onClick={resetComponent} className="btn btn-primary" id="submit-more-code-btn">
												<Trans>REFER MORE FRIENDS</Trans>
											</button>
										</div>
									</Col>
								</Row>
							</>
						)}
					</Col>
					<Col>
						<h4 className="title">
							<b>{activityData["title"] ? activityData.title : ""}</b>
						</h4>
						<p className="description">{activityData["description"] ? activityData.description : ""}</p>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};
export default IsLoadingHOC(ReferFriend, "Loading....");
