import { SAVE_USER_CONFIG, USER_POINT_BALANCE_SAVE, NOTIFICATION_CENTER_CREATE, NOTIFICATION_CENTER_UPDATE } from "./types";
import api from "../../api/api.js";
import { menu, contests } from "../../data";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// const talentBaseUrl = process.env.REACT_APP_TANENT_BASE_URL;
export const portalPointAction = (id, actionType = [], limit = 4) => async (dispatch) => {
	try {
		//let limit = 4;
		const result = await api.get(`/s1/activity?page=${id - 1}&limit=${limit}&actiontype=${actionType.toString()}`, null, 2);
		if (result.data) {
			return {
				end: result.data.metaData.totalRecs > limit * id ? true : false,
				data: result.data.content,
			};
		} else {
			return { end: false, data: [] };
		}
	} catch (ex) {
		throw ex;
	}
};
export const activityStatusAction = async (id) => {
	try {
		const result = await api.get(`/s2/points/status/${id}`, null);
		if (result.data) {
			return result.data;
		} else {
			return false;
		}
	} catch (ex) {
		throw ex;
	}
};
export const getContest = () => async (dispatch) => {
	return new Promise((resolve, reject) => {
		resolve(contests.body.portalPoints);
	});
};

export const pointHistoryGet = () => async (dispatch) => {
	try {
		const data = await api.get(`/s2/points/0/5000`, null, 2);
		if (data) {
			dispatch(balanceGetAction());
			return data;
		} else {
			throw "Record not found!";
		}
	} catch (ex) {
		throw ex;
	}
};

export const balanceGetAction = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		const result = await api.get("/s2/points/balance", null);
		if (result.httpCode === 200) {
			resolve(result);
			dispatch({
				type: USER_POINT_BALANCE_SAVE,
				payload: result.data.pointBalance,
			});
		} else {
			if ("errors" in result && Array.isArray(result.errors)) {
				reject(result.errors);
			} else {
				if (typeof result === "object") {
					if (result["response"]) {
						reject(result["response"].data.errors);
					} else {
						reject([]);
					}
				} else {
					reject([]);
				}
			}
		}
	});
};

export const getManu = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		resolve(menu);
	});
};

export const uiconfig = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		//axios.get(`${baseUrl}/tenant/testTenant2`)
		axios
			.post(`${baseUrl}/s1/campaign/mini`, "")
			.then(function(result) {
				// handle success
				if (result.data.httpCode === 200) {
					resolve(result.data);
					dispatch({ type: SAVE_USER_CONFIG, payload: result.data.data });
				} else {
					reject("error");
				}
			})
			.catch(function(error) {
				// handle error
				reject("error");
			});
	});
};

export const refreshToken = (token) => {
	axios
		.port(`${baseUrl}/s2/auth/refreshtoken`, {
			refreshToken: "eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjY5Njk2NjcsImV4cCI6MTYyNzAxMzY2N30.PPRCpcYkDMGMnHU1ci3s5GSeSnBkQ1oUzg87zMmRuso",
		})
		.then(function(response) {
			console.log(response);
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const uploadImageAction = (data) => {
	return api.post(`/s2/receipt`, data);
};
export const validateCodeAction = (data) => {
	return api.post(`/s1/package/validatecode`, data);
};
export const eligibleProductListAction = () => {
	return api.get(`/s1/product?limit=5000`, null);
};
export const rewardsListAction = (id = "") => {
	if (id === "") {
		return api.get(`/s2/rewards/campaign`, null);
	} else {
		return api.get(`/s2/rewards/campaign/${id}`, null);
	}
};
export const pgTokenAction = () => {
	return api.get(`/s2/pg/pgToken`, null);
};
export const rewardsOrderPaymentAction = (id, body) => {
	return api.put(`/s2/rewards/order/${id}/checkout`, body);
};
export const redeemRewardOrderAction = (id) => {
	return api.post(`/s2/rewards/campaign/redeem/${id}`, {});
};
export const notificationStatusUpdateAction = (uuid, data) => {
	return api.put(`/s1/notification/${uuid}`, data);
};
export const rewardsOrderAddressUpdateAction = (id, body) => {
	return api.put(`/s2/rewards/order/address/${id}`, body);
};
export const notificationGetAction = (date = "") => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		let apiurl = "/s1/notification?page=0&limit=5000";
		if (date !== "") {
			apiurl = `${apiurl}&date=${date}`;
		}
		const result = await api.get(apiurl, null);
		if (result.httpCode === 200) {
			if (result.data.content) {
				let notificationList = result.data.content.map((v) => ({ shortMsg: v.richTextMsg, url: v.shortMessage ? v.shortMessage : "", time: v.createdAt, isRead: v.isRead, uuid: v.UUID }));
				if (date !== "") {
					dispatch({
						type: NOTIFICATION_CENTER_UPDATE,
						payload: notificationList,
					});
				} else {
					dispatch({
						type: NOTIFICATION_CENTER_CREATE,
						payload: notificationList,
					});
				}
			}
			resolve(result);
		} else {
			if (date === "") {
				dispatch({
					type: NOTIFICATION_CENTER_CREATE,
					payload: [],
				});
			}
			if (typeof result === "object") {
				if (result["response"]) {
					reject(result["response"].data.errors);
				} else {
					reject([]);
				}
			} else {
				reject([]);
			}
		}
	});
};

export const ticketListAction = (id = "") => {
	if (id === "") {
		return api.get(`/s2/rewards/campaign`, null);
	} else {
		return api.get(`/s2/rewards/campaign/${id}`, null);
	}
};
export const ticketCreateAction = (body) => {
	return api.post(`/s1/support/`, body);
};
