import { SAVE_USER_CONFIG, NOTIFICATION_CENTER_CREATE, NOTIFICATION_CENTER_UPDATE } from "../actions/types";
let initialState = {
	user: {},
	language: "en",
	config: {},
	notificationCenter: [],
};
export default function(state = initialState, action) {
	switch (action.type) {
		case SAVE_USER_CONFIG:
			return { ...state, config: action.payload };
		case NOTIFICATION_CENTER_CREATE:
			return { ...state, notificationCenter: action.payload };
		case NOTIFICATION_CENTER_UPDATE: {
			let oldNotifications = JSON.parse(JSON.stringify(state.notificationCenter));
			let notifications = [];
			if (action.payload.length > 0) {
				let tempNotifications = [];
				for (let i in action.payload) {
					if (oldNotifications.filter((v) => v.uuid === action.payload[i].uuid).length === 0) {
						tempNotifications.push(action.payload[i]);
					}
				}
				notifications = tempNotifications.concat(oldNotifications);
			} else {
				notifications = oldNotifications;
			}
			return { ...state, notificationCenter: notifications };
		}
		default:
			return state;
	}
}
