import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import errorHtml from "../../components/Error";
import { resetPassword } from "../../redux/actions";
import { useHistory } from "react-router-dom";

const ResetPassword = ({ setLoading, resetPassword, location }) => {
	const history = useHistory();
	const [passwordConfirm, passwordConfirmSet] = useState("");
	const [password, setPassword] = useState("");
	const [errors, setErrors] = useState({ passwordConfirm: "", password: "" });
	const [token, setToken] = useState("");
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get("token");
		setToken(token);
	}, []);

	const onChangeHandle = (e) => {
		const { name, value } = e.target;
		console.log(name);
		if (name === "passwordConfirm") {
			if (password !== value && value.length) {
				setErrors({ ...errors, passwordConfirm: "Confirm Password should be same" });
			} else {
				value.length < 1 ? setErrors({ ...errors, passwordConfirm: "Password should not be empty" }) : setErrors({ ...errors, passwordConfirm: "" });
			}
			passwordConfirmSet(value);
		}
		if (name === "password") {
			value.length < 1 ? setErrors({ ...errors, password: "Password Should not be empty" }) : setErrors({ ...errors, password: "" });
			setPassword(e.target.value);
		}
	};

	const handleError = () => {
		let value = false;
		const error = {};
		if (password.length < 1) {
			error.password = "Password should not be empty";
			value = true;
		}
		if (passwordConfirm.length < 1) {
			error.passwordConfirm = "Password Confirm Should not be empty";
			value = true;
		}
		if (password !== passwordConfirm && passwordConfirm.length) {
			error.passwordConfirm = "Confirm Password should be same";
			value = true;
		} else if (!passwordConfirm.length) {
			error.passwordConfirm = "Password Confirm Should not be empty";
			value = true;
		} else {
			error.passwordConfirm = "";
		}
		return { value, error };
	};

	const onSubmit = () => {
		const { value, error } = handleError();
		if (value) {
			setErrors({ ...errors, ...error });
			return false;
		}
		setLoading(true);
		resetPassword({ token, password })
			.then((response) => {
				console.log(response);
				setLoading(false);
				toast.success(response.message);
				history.push("/login");
			})
			.catch((error) => {
				console.log(error);
				error.map((item) => {
					toast.error(item.message);
				});
				setLoading(false);
			});
	};
	console.log(errors);
	return (
		<div className="register-page">
			<Card>
				<form>
					<h1 className="main-heading">Reset Password</h1>
					<div className="form-group row theme-form-password">
						<label htmlFor="inputPassword" className="col-sm-2 col-form-label">
							Password
						</label>
						<div className="col-sm-10">
							<input type="password" data-test="reset-password-input" name="password" onChange={onChangeHandle} className="form-control" id="inputPassword" placeholder="Password" />
						</div>
						{errorHtml(errors.password)}
					</div>
					<div className="form-group row theme-form-password-confirm">
						<label htmlFor="inputPasswordConfirm" className="col-sm-2 col-form-label">
							Password Confirm
						</label>
						<div className="col-sm-10">
							<input type="password" data-test="reset-password-confirm-input" name="passwordConfirm" onChange={onChangeHandle} className="form-control" id="inputPasswordConfirm" placeholder="Password" />
						</div>
						{errorHtml(errors.passwordConfirm)}
					</div>
					<div className="row submit">
						<button onClick={onSubmit} data-test="reset-password-submit" type="button" className="btn">
							Submit
						</button>
					</div>
				</form>
			</Card>
		</div>
	);
};

export default connect(null, { resetPassword })(IsLoadingHOC(ResetPassword, "Wait ...."));
