import React from "react";
import { Container } from "react-bootstrap";
//import {footerCopyRight} from "../data";
import { useTranslation } from "react-i18next";

const CopyRight = () => {
	const { t } = useTranslation();
	return (
		<Container fluid>
			<div className="copy-text">
				<h6>{t("copyrightText")}</h6>
			</div>
			<p className="tierlogic">
				{/* {footerCopyRight.poweredText} */}
				{t("Powered By ")}
				{/* <a href="https://www.3tierlogic.com/">
					<img alt="3TL-logo" src="https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/assets/home/3tl.png"/>

				</a> */}
			</p>
		</Container>
	);
};

export default CopyRight;
