export const USER_DATA_SAVE = "USER_DATA_SAVE";
export const LOG_OUT = "LOG_OUT";

export const SAVE_USER_CONFIG = "SAVE_USER_CONFIG";
export const SAVE_ACCESS_TOKEN = "SAVE_ACCESS_TOKEN";
export const SAVE_REFRESH_TOKEN = "SAVE_REFRESH_TOKEN";

export const EARNPOINT_VIDEO_PREVIEW = "EARNPOINT_VIDEO_PREVIEW";
export const USER_POINT_BALANCE_SAVE = "USER_POINT_BALANCE_SAVE";
export const EARNPOINT_VIDEO_PLAYSTATUS = "EARNPOINT_VIDEO_PLAYSTATUS";

export const NOTIFICATION_CENTER_CREATE = "NOTIFICATION_CENTER_CREATE";
export const NOTIFICATION_CENTER_UPDATE = "NOTIFICATION_CENTER_UPDATE";
