import { EARNPOINT_VIDEO_PREVIEW, EARNPOINT_VIDEO_PLAYSTATUS } from "../actions/types";
let initialState = {
	actionType: "",
	activity: "video",
	cardHeaderImage: "",
	channel: "youtube",
	data: "h3h6h6g5",
	description: "Description",
	points: 0,
	pointsEnabled: true,
	times: 1,
	title: "Title",
	id: "",
	maxAttempt: 1,
	videoPlayStatus: {},
};
export default function(state = initialState, action) {
	switch (action.type) {
		case EARNPOINT_VIDEO_PREVIEW: {
			const { actionType, activity, cardHeaderImage, channel, data, description, points, pointsEnabled, times, title, id, maxAttempt } = action.payload;
			return { ...state, actionType, activity, cardHeaderImage, channel, data, description, points, pointsEnabled, times, title, id, maxAttempt };
		}
		case EARNPOINT_VIDEO_PLAYSTATUS:
			return { ...state, videoPlayStatus: action.payload };
		default:
			return state;
	}
}
