import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import IsLoadingHOC from "./IsLoadingHOC";
import { eligibleProductListAction } from "./../redux/actions";
import TableReport from "./TableReport";
const EligibleProductList = (props) => {
	const { t } = useTranslation();
	const [productList, setProductList] = useState([]);
	const headerFormatter = (column, colIndex) => {
		return (
			<label>
				{" "}
				{column.text}{" "}
				<OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props) => <Tooltip {...props}>{`${t("Submit Code")}: ${t("Look for an insert inside for a unique code to submit for validation")} ${t("Receipt Upload")}: ${t("Upload image of receipt proof-of-purchase to validate")}.`}</Tooltip>} placement="bottom">
					<span className="eligible-product-list-tooltrip-span">?</span>
				</OverlayTrigger>
			</label>
		);
	};
	const columns = [
		{ dataField: "productName", text: t("Title") },
		{ dataField: "validationType", text: t("Validation Type"), headerFormatter: headerFormatter },
		{ dataField: "productType", text: t("Format") },
		{ dataField: "UPC", text: t("UPC") },
	];
	useEffect(() => {
		props.setLoading(true);
		eligibleProductListAction()
			.then((responseData) => {
				if (responseData.httpCode === 200) {
					setProductList(responseData.data.content);
				} else {
					setProductList([]);
					console.log("responseData", responseData);
				}
				props.setLoading(false);
			})
			.catch((err) => {
				props.setLoading(false);
				setProductList([]);
				console.log("Response Error:", JSON.stringify(err));
			});
	}, []);

	return (
		<Container fluid>
			<div className="validate-purchase-div">
				<Container fluid>
					<Row xs={1} md={1} lg={1}>
						<h2 className="h2-title">
							<Trans>Eligible Products</Trans>
						</h2>
					</Row>
					<Row xs={1} md={1} lg={1}>
						<label className="label-disc">
							<Trans>Product description</Trans>
						</label>
					</Row>
					<Row>
						<Col xs lg="12" className="text-center">
							<TableReport keyField="id" data={productList} columns={columns} />
						</Col>
					</Row>
				</Container>
			</div>
		</Container>
	);
};

export default IsLoadingHOC(EligibleProductList, "Loading....");
