import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
const RichTextEditor = (props) => {
	const [richtext, setRichtext] = useState("");
	useEffect(() => {
		setRichtext(EditorState.createEmpty());
	}, []);
	const handleOnChange = (editorState) => {
		if (props.parentCallback) {
			props.parentCallback(props.id, draftToHtml(convertToRaw(editorState.getCurrentContent())));
		}
		setRichtext(editorState);
	};
	return <Editor editorState={richtext} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" onEditorStateChange={handleOnChange} />;
};

export default React.memo(RichTextEditor);
