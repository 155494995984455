import axios from "axios";
import { store } from "../redux/store";
import { saveAccessToken, saveRefreshToken } from "../redux/actions";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
	(config) => {
		let userLang = navigator.language || navigator.userLanguage;
		let lang = "en";
		switch (userLang) {
			case "en-GB":
				lang = "en";
				break;
			case "fr-CA":
				lang = "fr";
				break;
			case "es":
				lang = "es";
				break;
			default:
				lang = "en";
		}
		const accessToken = store.getState("user").user.accessToken;
		if (accessToken) {
			config.headers["Authorization"] = accessToken;
		}
		config.headers["Accept-Language"] = lang;
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	function(error) {
		const originalRequest = error.config;
		let refreshToken = store.getState("user").user.refreshToken;
		if (refreshToken && error.response.data.httpCode === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			return axios
				.post(`${baseUrl}/s2/auth/refreshtoken`, { refreshToken: refreshToken })
				.then((res) => {
					if (res["data"]) {
						if (res.data.httpCode === 200) {
							store.dispatch(saveAccessToken(res.data.accessToken));
							store.dispatch(saveRefreshToken(res.data.refreshToken));
							return axios(originalRequest);
						}
					} else {
						console.log("Refresh token Response", res);
						store.dispatch({ type: "LOG_OUT" });
					}
				})
				.catch((error) => {
					console.log("Refresh Token expire", error);
					store.dispatch({ type: "LOG_OUT" });
				});
		}
		return Promise.reject(error);
	}
);

//functions to make api calls

// const api = {
// 	signup: (body) => {
// 		return axios.post(`${baseUrl}/auth/signup`, body);
// 	},
// 	login: (body) => {
// 		return axios.post(`${baseUrl}/auth/login`, body);
// 	},
// 	refreshToken: (body) => {
// 		return axios.post(`${baseUrl}/auth/refresh_token`, body);
// 	},
// 	logout: (body) => {
// 		return axios.delete(`${baseUrl}/auth/logout`, body);
// 	},
// 	getProtected: () => {
// 		return axios.get(`${baseUrl}/default/api/identities`);
// 	},
// };

class Api {
	post(path, data = null) {
		return this.send(path, "post", data);
	}

	put(path, data = null) {
		return this.send(path, "put", data);
	}

	get(path, data = null) {
		return this.send(path, "get", data);
	}

	delete(path, data = null) {
		return this.send(path, "delete", data);
	}

	send(url, method, data) {
		//console.log("lang", lang);
		let uri = `${baseUrl}${url}`;

		return new Promise((resolve, reject) => {
			// console.log({
			// 	uri, method, data,
			// });
			// let option = "";
			// if (method === "GET") {
			// 	option = { method, headers };
			// } else {
			let option = { method, url: uri, data: data };
			// }
			//console.log("option", option);

			axios(option)
				.then((response) => {
					//console.log("success axios", response);
					return response.data;
				})
				.then((responseData) => {
					// debugger;
					//console.log("data final", responseData);
					resolve(responseData);
				})
				.catch((error) => {
					//console.log("error.....", error, error["response"]);
					if (error["response"]) {
						resolve(error.response.data);
					} else {
						resolve(error);
					}
				});
		});
	}
}

const api = new Api();
export default api;
//export default axios;
