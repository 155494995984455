import React from "react";
import PortalPointList from "../../components/PortalPointList";
import CarouselCustom from "../../components/CarouselCustom";
import Contasts from "../../components/Contasts";
class Home extends React.Component {
	render() {
		return (
			<>
				<CarouselCustom />
				<PortalPointList pagesize={4} />
				<Contasts />
			</>
		);
	}
}

export default Home;
