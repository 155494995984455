import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { getUserDetails, updateUserDetails } from "../../redux/actions";
import { connect } from "react-redux";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Calendar from "react-calendar";
var validator = require("validator");

const Profile = ({ getUserDetails, updateUserDetails, setLoading }) => {
	const { t } = useTranslation();
	const confige = useSelector((state) => state.app.config);
	const user = useSelector((state) => state.user);
	const history = useHistory();
	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({firstName:'',lastName:'',email:'',username:'',cellPhone:'',zip:'',})
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({ firstName: "", email: "", homePhone: "", cellPhone: "", lastName: "", username: "", address: "", country: "", state: "", suite: "", zip: "", city: "", shippingInstructions: "", receiveEmail: "", receiveSms: "" });
	const [calender, setCalender] = useState(false);

	useEffect(() => {
		setLoading(true);
		getUserDetails(user.id)
			.then((response) => {
				console.log("user details response", response);
				setLoading(false);
				setData({ ...data, ...response.data });
			})
			.catch((error) => {
				setLoading(false);
				console.log("error", error);
			});
		let items = {};
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				items[element.name] = element.validation;
			});
		}

		setValidation(items);
	}, []);
	const handleChange = (e) => {
		const { name, value } = e.target;
		console.log(name, value);
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key]) {
					errorMessage = t("Should not be less then", { count: validationArray[key] });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = t("Should not be greater then", { count: validationArray[key] });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = t("Should not be empty");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = t("Please enter a valid email");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key]) {
				if (value.search(/[a-z]/) < 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value)) {
					errorMessage = t("Should have atleast one special letter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	const onSubmit = () => {
		console.log("data", data);
		let errorArray = {};
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			if (value !== null) {
				for (let key in validationArray) {
					if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
						errorMessage = t("Please enter a valid email");
					} else if (key === "minLength" && value.length < validationArray[key]) {
						errorMessage = t("Should not be less then", { count: validationArray[key] });
					} else if (key === "maxLength" && value.length > validationArray[key]) {
						errorMessage = t("Should not be greater then", { count: validationArray[key] });
					} else if (key === "mandatory" && validationArray[key] && !value.length) {
						errorMessage = t("Should not be empty");
					} else if (key === "upperCaseReq" && value.search(/[A-Z]/) < 0) {
						errorMessage = t("Should have atleast one Upper Case latter");
					} else if (key === "lowerCaseReq" && value.search(/[a-z]/) < 0) {
						errorMessage = t("Should have atleast one Lower Case latter");
					} else if (key === "specialCharReq") {
						var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
						if (!format.test(value)) {
							errorMessage = t("Should have atleast one special letter");
						}
					}
				}
				errorArray[name] = errorMessage;
			}
		}
		setError(errorArray);
		console.log("error Array", errorArray);

		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!check) {
			setLoading(true);
			updateUserDetails(user.id, data)
				.then((response) => {
					console.log("response", response);
					toast.success(response.message);
					setTimeout(() => {
						history.push("/");
					}, 1000);

					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					error.map((item) => {
						toast.error(item.message);
					});
					setLoading(false);
				});
		}
	};

	const formatDate = (value) => {
		const dd = String(value.getDate()).padStart(2, "0");
		const mm = String(value.getMonth() + 1).padStart(2, "0"); //January is 0!
		const yyyy = value.getFullYear();
		const formatedDate = yyyy + "-" + mm + "-" + dd;
		return formatedDate;
	};

	const handleDate = (value) => {
		const formatedDate = formatDate(value);
		setCalender(false);
		setData({ ...data, birth: formatedDate });
	};
	console.log(data.birth);
	return (
		<div className="profile-page">
			<Card className="container">
				<form>
					<h1 className="main-heading">{t("Manage Your Account")}</h1>
					{/*[START:FIRSTNAME]*/}
					<div className="form-group row theme-profile-firstName">
						<label htmlFor="first-name" className="col-sm-2 col-form-label profile-firstName-label">
							<Trans>lang_key_fname</Trans>
						</label>
						<div className="col-sm-10">
							<input type="text" data-test="profile-firstName" name="firstName" className="profile-firstName form-control" value={data.firstName !== null ? data.firstName : ""} onChange={handleChange} id="first-name" placeholder={t("lang_key_fname")} />
							<h5 className="input-error">{error.firstName}</h5>
						</div>
					</div>
					{/*[END:FIRSTNAME]*/}

					{/*[START:LASTNAME]*/}
					<div className="form-group row theme-profile-lastName">
						<label htmlFor="last-name" className="col-sm-2 col-form-label profile-lastName-label">
							<Trans>lang_key_lname</Trans>
						</label>
						<div className="col-sm-10">
							<input type="text" data-test="profile-lasttName" name="lastName" className="profile-lastName form-control" value={data.lastName !== null ? data.lastName : ""} onChange={handleChange} id="last-name" placeholder={t("lang_key_lname")} />
							<h5 className="input-error">{error.lastName}</h5>
						</div>
					</div>
					{/*[END:LASTNAME]*/}

					{/*[START:USERNAME]*/}
					<div className="form-group row theme-profile-username">
						<label htmlFor="user-name" className="col-sm-2 col-form-label">
							<Trans>lang_key_username</Trans>
						</label>
						<div className="col-sm-10">
							<input type="text" data-test="profile-username" name="username" className="profileUserName form-control" value={data.username !== null ? data.username : ""} onChange={handleChange} id="user-name" placeholder={t("lang_key_username")} disabled />
							<h5 className="input-error">{error.username}</h5>
						</div>
					</div>
					{/*[END:USERNAME]*/}

					{/*[START:EMAIL]*/}
					<div className="form-group row theme-profile-email">
						<label htmlFor="user-email" className="col-sm-2 col-form-label">
							<Trans>lang_key_email</Trans>
						</label>
						<div className="col-sm-10">
							<input type="email" data-test="profile-email" name="email" className="profileEmail form-control" value={data.email} onChange={handleChange} id="email" placeholder={t("lang_key_email")} />
							<h5 className="input-error">{error.email}</h5>
						</div>
					</div>
					{/*[END:EMAIL]*/}

					
					
					

					
					
					

					
					
					

					
					
					

					
					
					

					{/*[START:ZIP]*/}
					<div className="form-group row theme-profile-zip">
						<label htmlFor="zip" className="col-sm-2 col-form-label">
							<Trans>Zip</Trans>
						</label>
						<div className="col-sm-10">
							<input type="text" data-test="profile-zip" name="zip" onChange={handleChange} value={data.zip ? data.zip : ""} className="form-control" id="zip" placeholder={t("Zip")} />
							<h5 className="input-error">{error.zip}</h5>
						</div>
					</div>
					{/*[END:ZIP]*/}

					
					
					

					
					
					

					
					
					

					
					
					

					{/*[START:CELLPHONE]*/}
					<div className="form-group row theme-profile-homePhone">
						<label htmlFor="cellPhone" className="col-sm-2 col-form-label">
							<Trans>Cell Phone</Trans>
						</label>
						<div className="col-sm-10">
							<input type="number" data-test="profile-cellPhone" name="cellPhone" onChange={handleChange} value={data.cellPhone ? data.cellPhone : ""} className="form-control" id="cellPhone" placeholder={t("Cell Phone")} />
							<h5 className="input-error">{error.cellPhone}</h5>
						</div>
					</div>
					{/*[END:CELLPHONE]*/}

					
					
					

					
					
					

					
					
					

					<div className="row submit">
						<button type="button" id="profile-submit-btn" data-test="profile-submit" onClick={onSubmit} className="btn">
							<Trans>Save Profile</Trans>
						</button>
					</div>
				</form>
			</Card>
		</div>
	);
};

export default connect(null, { getUserDetails, updateUserDetails })(IsLoadingHOC(Profile, "Wait ....."));
