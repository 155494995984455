import React from "react";
import {Carousel, Container} from "react-bootstrap";
import {carouselData} from "../data";

const CarouselCustom  = () => {
	return (
		<Container className="carouselCustom" fluid>
			<Carousel>
				{
					carouselData.map((item, index) => {
						return (
							<Carousel.Item key={index}>
								<img
									className="d-block w-100"
									src={item.img}
									alt={item.title}
								/>
								{/* <Carousel.Caption>
						<h3>First slide label</h3>
						<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
					</Carousel.Caption> */}
							</Carousel.Item>
						);
					})
				}
				
			</Carousel>
		</Container>
		
	);
};

export default CarouselCustom;