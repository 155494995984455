import React from "react";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import { facebookLogin, googleLoign } from "../redux/actions";
import IsLoadingHOC from "./IsLoadingHOC";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

class SocialLogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			name: "",
			accessToken: "",
			email: "",
			roles: [],
			app: "",
		};
	}
	responseFacebook = (response) => {
		const requestOptions = {
			id: response.id,
			email: response.email,
			name: response.name,
			accessToken: response.accessToken,
		};
		this.props.setLoading(true);
		this.props
			.facebookLogin(requestOptions)
			.then((response) => {
				console.log("res", response);
				this.props.setLoading(false);
				toast.success("Login Successfully");
				this.props.history.push("/");
			})
			.catch((error) => {
				console.log("err", error);
				this.props.setLoading(false);
			});
	};
	responseGoogle = (response) => {
		const requestOptions = {
			id: response.googleId,
			email: response.profileObj ? response.profileObj.email : "",
			name: response.profileObj ? response.profileObj.name : "",
			accessToken: response.accessToken,
		};
		this.props.setLoading(true);
		this.props
			.googleLoign(requestOptions)
			.then((response) => {
				console.log("res", response);
				this.props.setLoading(false);
				this.props.history.push("/");
				toast.success("Login Successfully");
			})
			.catch((error) => {
				console.log("err", error);
				this.props.setLoading(false);
			});
	};
	render() {
		const { error } = this.state;
		const { t } = this.props;
		if (error) {
			return <div>Error: {error.message}</div>;
		} else {
			return (
				<section className="social-login">
					<div className="facebook-login">
						<FacebookLogin textButton={t("Login with Facebook")} appId="991057908096375" autoLoad={false} fields="name,email,picture" scope="public_profile,email" callback={this.responseFacebook} />
					</div>
					<div className="google-login">
						<GoogleLogin buttonText={t("login")} clientId="652043195533-uhd0dohl1l75uomaaoq76atbd6lfck5g.apps.googleusercontent.com" onSuccess={this.responseGoogle} onFailure={this.responseGoogle} cookiePolicy={"single_host_origin"} />
					</div>
				</section>
			);
		}
	}
}

export default connect(null, { facebookLogin, googleLoign })(IsLoadingHOC(withRouter(withTranslation()(SocialLogin))));
