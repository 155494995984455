import React, { useEffect, useState } from "react";
import { ticketListAction } from "../../redux/actions";
import { Button, Container, Col, Row, Table } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { Trans, useTranslation } from "react-i18next";
import NewTicket from "./NewTicket";
import { Link } from "react-router-dom";

const TicketList = (props) => {
	const { t } = useTranslation();
	const pageLimit = 8;
	const [pageNo, setPageNo] = useState(0);
	const [viewPaginationBtn, setViewPaginationBtn] = useState(false);
	const [createTicket, setCreateTicket] = useState(false);
	const [data, setData] = useState([]);
	const [ticketSelectBox, setTicketSelectBox] = useState("");
	let [ticketListData, setTicketListData] = useState([]);

	const statusList = ["Active", "On Hold", "Closed"];

	useEffect(() => {
		getTicketList();
	}, []);
	const getTicketList = () => {
		props.setLoading(true);
		setPageNo(0);
		ticketListAction()
			.then((response) => {
				if (response.data) {
					let ticket = response.data;
					setTicketListData(ticket);
				}
				props.setLoading(false);
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
			});
	};
	const getNextPageData = () => {
		let tempRewardList = JSON.parse(JSON.stringify(ticketListData));
		if (ticketSelectBox !== "") {
			tempRewardList = tempRewardList.filter((v) => v.type === ticketSelectBox);
		}
		let pageData = tempRewardList.slice(0, pageNo * pageLimit + pageLimit);
		setData(pageData);
		if (pageNo * pageLimit + pageLimit >= tempRewardList.length) {
			setViewPaginationBtn(false);
		} else {
			setViewPaginationBtn(true);
		}
	};
	const filterRewardList = async (e) => {
		setPageNo(0);
		setTicketSelectBox(e.target.value);
	};
	useEffect(() => {
		getNextPageData();
	}, [ticketListData, ticketSelectBox, pageNo]);
	const newTicketBtnClicked = (reloadTicket) => {
		if (reloadTicket) {
			getTicketList();
		}
		setCreateTicket(false);
	};
	if (createTicket) {
		return <NewTicket parentCallback={newTicketBtnClicked} />;
	} else {
		return (
			<Container className="help-desk-container">
				<Container fluid>
					<Container fluid>
						<hr />
						<Row xs={3} md={3}>
							<Col>
								<h2 className="heading2">
									<Trans>All</Trans> <Trans>Support</Trans> <Trans>Tickets</Trans>
								</h2>
							</Col>
							<Col>
								<Row>
									<Col md={4}>
										<Trans>Ticket</Trans> <Trans>Status</Trans>
									</Col>
									<Col md={8}>
										<select className="form-control" id="ticketStatus" value={ticketSelectBox} onChange={filterRewardList}>
											<option value="">{`${t("Show")} ${t("ALL")}`}</option>
											{statusList.map((v, i) => {
												return (
													<option key={i + "_typeOption"} value={v}>
														{v}
													</option>
												);
											})}
										</select>
									</Col>
								</Row>
							</Col>
							<Col className="text-right">
								<Button variant="secondary" onClick={(e) => setCreateTicket(true)} disabled={createTicket ? true : false} id="new-ticket-btn" className="cart-button btn btn-warning">
									<Trans>New</Trans> <Trans>Ticket</Trans>
								</Button>
							</Col>
						</Row>
						<Table className="table table-striped" responsive="sm" responsive="lg" responsive="md" responsive="xs">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<Trans>Ticket</Trans>
									</th>
									<th>
										<Trans>Subject</Trans>
									</th>
									<th>
										<Trans>Created</Trans>
									</th>
									<th>
										<Trans>Last Updated</Trans>
									</th>
									<th>
										<Trans>Status</Trans>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => {
									return (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>
												<Link to={`/help-desk/${item.id}`} id={`ticket-btn-${item.id}`}>
													{item.id}
												</Link>
											</td>
											<td>{item.itemName}</td>
											<td>{new Date().toLocaleString()}</td>
											<td> {new Date().toLocaleString()}</td>
											<td> Active</td>
											<td>
												<Link to={`/help-desk/${item.id}`} id={`ticket-btn-${item.id}`} className="cart-button btn btn-primary">
													<Trans>Details</Trans>
												</Link>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
						<hr />
						<div className="text-center" style={{ width: "100%" }}>
							{viewPaginationBtn ? (
								<Button variant="primary" id="view-more-help-desk-ticket-btn" className="viewMore" onClick={() => setPageNo(pageNo + 1)}>
									<Trans>View More</Trans> <Trans>Tickets</Trans>
								</Button>
							) : (
								<Button variant="secondary" disabled id="view-more-help-desk-ticket-btn" className="viewMore">
									<Trans>All</Trans> <Trans>Tickets</Trans> <Trans>Shown</Trans>
								</Button>
							)}
						</div>
					</Container>
				</Container>
			</Container>
		);
	}
};

export default IsLoadingHOC(TicketList, "Loading....");
