import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/theme.css";
import "./assets/css/theme.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import routes from "./routing/Routing";
import "./child-theme/css/theme-child.css";
import "react-calendar/dist/Calendar.css";
import { connect } from "react-redux";
import { uiconfig, getIdentities } from "./redux/actions";
import { ToastContainer } from "react-toastify";

const App = ({ uiconfig, getIdentities }) => {
	useEffect(() => {
		(async () => {
			uiconfig()
				.then((response) => {})
				.catch((error) => {
					console.log(error);
				});
		})();
	}, [uiconfig]);
	return (
		<>
			<Router>{routes}</Router>
			<ToastContainer />
		</>
	);
};
export default connect(null, { uiconfig, getIdentities })(App);
