import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import TopRight from "./TopRight";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Menu = ({ data }) => {
	const { t } = useTranslation();
	const user = useSelector(({ user }) => user);
	return (
		<Fragment>
			<button id="button-navbar-toggler" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span id="span-navbar-toggler-icon" className="navbar-toggler-icon"></span>
			</button>

			<div className="collapse navbar-collapse top-navigation" id="navbarSupportedContent">
				<ul className="navbar-nav">
					{/*[START:VALIDATEPURCHASE]*/}
					<li className="nav-item">
						<a id="href-validate-purchase" className="nav-link" href="#/">
							{t("Validate Purchase")}
						</a>
						<ul className="navbar-nav">
							{/*[START:SUBMITCODE]*/}
							<Link id="href-submit-code" to="/validate-code">
								<li className="nav-item">{t("Submit Code")}</li>
							</Link>
							{/*[END:SUBMITCODE]*/}

							{/*[START:DIGITALMOVIE]*/}
							<li className="nav-item">
								<a id="href-digital-movie" href="/">
									{t("Digital Movie")}
								</a>
							</li>
							{/*[END:DIGITALMOVIE]*/}

							{/*[START:RECEIPTUPLOAD]*/}
							<Link id="href-receipt-upload" to="/upload-receipt">
								<li className="nav-item">{t("Receipt Upload")}</li>
							</Link>
							{/*[END:RECEIPTUPLOAD]*/}

							{/*[START:ELIGIBLEMOVIES]*/}
							<Link id="href-eligible-products" to="/eligible-product">
								<li className="nav-item">{t("Eligible Products")}</li>
							</Link>
							{/*[END:ELIGIBLEMOVIES]*/}
						</ul>
					</li>
					{/*[END:VALIDATEPURCHASE]*/}

					{/*[START:REWARDS]*/}
					<Link className="nav-link nav-link-custom" id="href-rewards" to="/rewards">
						<li className="nav-item nav-item-custom">{t("Rewards")}</li>
					</Link>
					{/*[END:REWARDS]*/}

					
					
					

					{/*[START:EARNPOINTS]*/}
					<Link className="nav-link nav-link-custom" id="href-earn-points" to="/earnpoints">
						<li className="nav-item nav-item-custom">{t("Earn Points")}</li>
					</Link>
					{/*[END:EARNPOINTS]*/}

					{/*[START:ALLACCESS]*/}
					{user.accessToken && (
						<li className="nav-item">
							<a id="href-all-access" className="nav-link" href="#/">
								{t("All Access")}
							</a>
							<ul className="navbar-nav">
								{/*[START:ALLMYACCESS]*/}
								<li className="nav-item">
									<a id="href-all-my-access" href="/">
										{t("All My-Access")}
									</a>
								</li>
								{/*[END:ALLMYACCESS]*/}
								{/*[START:MYMOVIEFEATURES]*/}
								<li className="nav-item">
									<a id="href-my-movie-features" href="/">
										{t("My Movie Features")}
									</a>
								</li>
								{/*[END:MYMOVIEFEATURES]*/}
							</ul>
						</li>
					)}
					{/*[END:ALLACCESS]*/}

					{/*[START:FANZONE]*/}
					<li className="nav-item">
						<a id="href-fan-zone" className="nav-link" href="#/">
							{t("Fan Zone")}
						</a>
					</li>
					{/*[END:FANZONE]*/}

					{/* My All-Access , Points History, My Rewards, Profile, Connect Movie ANYHERE, Logout */}
					<TopRight />
				</ul>
			</div>
		</Fragment>
	);
};

export default Menu;
