import React from "react";
import { Container } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

const TicketDetails = (props) => {
	return (
		<Container className="help-desk-container">
			<Container fluid>
				<Container fluid>
					<Container fluid>
						<div className="label">
							<Link id="headerParentLink" className="parent-link" to="/help-desk">
								<Trans>Help Desk</Trans>
							</Link>{" "}
							{"Ticket Details"}
						</div>
						<br />
						<br />
						<h1 style={{ textAlign: "center" }}>Help Desk Ticket details coming soon</h1>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</Container>
				</Container>
			</Container>
		</Container>
	);
};

export default IsLoadingHOC(TicketDetails, "Loading....");
