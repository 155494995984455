import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { notificationStatusUpdateAction, notificationGetAction } from "../redux/actions";
const NotificationCenter = (props) => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [sidePanelDisplay, setSidePanelDisplay] = useState(false);
	const [viewPaginationBtn, setViewPaginationBtn] = useState(false);
	const notificationList = useSelector(({ app }) => (app.notificationCenter ? app.notificationCenter : []));
	const pageLimit = 4;
	const [pageNo, setPageNo] = useState(0);
	const dispatch = useDispatch();
	useEffect(() => {
		if (props.showNotification) {
			setSidePanelDisplay(true);
		} else {
			setSidePanelDisplay(false);
		}
	}, [props.showNotification]);
	const closePanel = (uuid = "") => {
		if (uuid !== "") {
			let requestData = { isRead: true };
			notificationStatusUpdateAction(uuid, requestData)
				.then((res) => {
					dispatch(notificationGetAction());
				})
				.catch((err) => {
					console.log("error", err);
				});
		}
		props.parentCall(!props.showNotification);
	};
	function isValidUrl(_string) {
		const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
		return matchPattern.test(_string);
	}
	const getNextPageData = () => {
		let tempList = JSON.parse(JSON.stringify(notificationList));
		let pageData = tempList.slice(0, pageNo * pageLimit + pageLimit);
		setData(pageData);
		if (pageNo * pageLimit + pageLimit >= tempList.length) {
			setViewPaginationBtn(false);
		} else {
			setViewPaginationBtn(true);
		}
	};
	useEffect(() => {
		getNextPageData();
	}, [notificationList, pageNo]);

	return (
		<Container id="mySidepanel" className="notification-sidepanel" style={{ display: sidePanelDisplay ? "block" : "none" }}>
			<h5>
				{t("Notification Center")}
				<Button className="closebtn" onClick={(e) => closePanel("")} id="closeNotificationBtn">
					X
				</Button>
			</h5>
			<hr />
			<Row xs={1} md={1} lg={1}>
				{data.map((v, i) => {
					return (
						<>
							<Col key={`notification-key-${i}`} id={`notification-msg-${v.uuid}`} xs lg="10" className={v.isRead ? "" : "not-read-notificaion"}>
								{v.shortMsg}
							</Col>
							<Col key={`notification-key-link-${i}`} xs lg="2">
								{isValidUrl(v.url) ? (
									<a id={`notification-id-${v.uuid}`} href={v.url} onClick={(e) => closePanel(!v.isRead ? v.uuid : "")} target="new">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
											<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
										</svg>
									</a>
								) : (
									<Link id={`notification-id-${v.uuid}`} to={v.url !== "" ? v.url : false} onClick={(e) => closePanel(!v.isRead ? v.uuid : "")}>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
											<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
										</svg>
									</Link>
								)}
							</Col>
							<hr />
						</>
					);
				})}
				<Row className="justify-content-md-center">
					<Col xs lg="6">
						{notificationList.length > 0 ? (
							viewPaginationBtn ? (
								<Button className="viewMore" onClick={() => setPageNo(pageNo + 1)} id="notification-view-more-btn">
									{t("View More")}
								</Button>
							) : (
								<Button className="viewMore" disabled={true} id="notification-all-btn">
									{t("You're all caught up")}
								</Button>
							)
						) : (
							""
						)}
					</Col>
				</Row>
			</Row>
		</Container>
	);
};
export default React.memo(NotificationCenter);
