import React from "react";
import { logout } from "../redux/actions";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
const Logout = ({ logout }) => {
	const handleLogout = () => {
		console.log("working dispatch");
		logout();
	};
	return (
		<button type="button" id="button-logout" onClick={handleLogout} className="btn btn-secondary theme-logout-button-top-right">
			<Trans>Logout</Trans>
		</button>
	);
};

export default connect(null, { logout })(Logout);
