import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EARNPOINT_VIDEO_PLAYSTATUS } from "../redux/actions/types";
const PrivateRoute = ({ accessToken, adminUser, component: Component, layout: Layout, ...rest }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const { earnpoint } = useSelector((state) => state);
	const dispatch = useDispatch();
	useEffect(() => {
		updateVideoStatus(location);
	}, [location]);
	const updateVideoStatus = (location) => {
		if (earnpoint.videoPlayStatus["state"] === "started" && location.pathname.indexOf("/earnpoints-action-preview/") < 0) {
			toast.error(`${t("Sorry")}! ${t("You haven't watched the full video, no points will be credited")}!`);
			dispatch({ type: EARNPOINT_VIDEO_PLAYSTATUS, payload: {} });
		}
	};
	return (
		<Route
			{...rest}
			render={(props) =>
				accessToken ? (
					<Layout>
						<Component {...props} />
					</Layout>
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

const mapStateToProps = ({ user }) => {
	const { accessToken } = user;
	//console.log(accessToken);
	return {
		accessToken,
	};
};
export default connect(mapStateToProps)(PrivateRoute);
