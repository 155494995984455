import React, {useEffect, useState} from "react";
import Card from "./Card";
import {connect} from "react-redux";

import {getContest} from "../redux/actions";
import {Button, Container, Row, Col} from "react-bootstrap";

const Contests = (props) => {
	const [data, setData] = useState([]);
	useEffect(() => {
		props.getContest().then(response => {
			setData(response);
		});
	}, [props]);
	return (
		<Container fluid>
			<div className="portal-point-list-container">
				<Container fluid>
					<div className="label">
                   CONTESTS
					</div> 
				</Container>
				<Container fluid className="portal-point-list">
					<Row xs={1} md={2} lg={4}>
						{
							data.map((item, index) => {
								return (
									<Col key={index}>
										<Card>
											<img src={item.cardHeaderImage} alt="Avatar" style={{width:"100%"}}/>
											<h4 className="points-actions">Points: {item.points}</h4> 
											<h4 className="title">{item.title}</h4> 
											<Button className="cart-button">Enter Now</Button>
										</Card>
									</Col>);
							})
						}
					</Row>
					<div className="text-center" style={{width: "100%"}}>
						<Button variant="secondary" className="viewMore">View All Contests</Button>
					</div>
					
				
				</Container>
			</div>
		
		</Container>
		
	);
};

export default connect(null, {getContest})(Contests);